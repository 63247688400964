<ng-container *ngIf="column.type === TableColumnType.CUSTOM">
  <ng-container *ngTemplateOutlet="customColumnTemplate; context: { $implicit: value, column: column }"></ng-container>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.ID">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable || isEditingNew"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.STRING">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PASSWORD">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <div class="flex w-full items-center justify-center">
        <div class="flex w-full">
          <p-password
            [name]="column.name"
            class="table-edit-input w-full" pInputText type="text"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right w-full"
            [(ngModel)]="value[column.name]"
            [disabled]="!column.editable"
            [required]="column.required ?? false"
            [toggleMask]="true"
            [feedback]="false">
          </p-password>
        </div>
        <p-button class="btn icon-btn" icon="pi pi-copy" (onClick)="copy(value[column.name])"></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="output">
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.NUMBER">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="number" min="0"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }} <span *ngIf="column.currency">{{ column.currency }}</span>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.BOOLEAN">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-checkbox
        [name]="column.name"
        [binary]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
      </p-checkbox>
    </ng-template>
    <ng-template pTemplate="output">
      <p-checkbox
        [name]="column.name"
        [binary]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="true">
      </p-checkbox>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.DATE">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-calendar
        [name]="column.name"
        class="table-edit-input"
        dateFormat="yy-mm-dd"
        [showIcon]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
      </p-calendar>
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.COMMENT">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input" pInputText
        type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="value[column.name] && value[column.name] !== ''; else noComment">{{ value[column.name] }}</span>
      <ng-template #noComment>...</ng-template>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.DROPDOWN">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <span *ngIf="!column.dropdownOptions; else dropdown">Options not set</span>
      <ng-template #dropdown>
        <p-dropdown
          [name]="column.name"
          [options]="column.dropdownOptions?.options"
          [(ngModel)]="value[column.name]"
          [dataKey]="column.dropdownOptions?.dataKey"
          [optionLabel]="column.dropdownOptions?.optionLabel"
          [optionValue]="column.dropdownOptions?.optionValue"
          [placeholder]="(column.dropdownOptions?.placeholder ?? '') | translate"
          [filter]="column.dropdownOptions?.filterable"
          [filterBy]="column.dropdownOptions?.filterBy"
          [disabled]="!column.editable"
          [required]="column.required ?? false">
        </p-dropdown>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value) }}</span>
      <span
        *ngIf="!column.valuePipe && value[column.name] && value[column.name] !== ''">{{ value[column.name] }}</span>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.CHIPSELECT">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <app-chip-select
        *ngIf="column.chipSelectOptions"
        [optionLabel]="column.chipSelectOptions.itemLabel"
        [name]="column.name"
        [items]="column.chipSelectOptions.items"
        [quickSelectionItems]="column.chipSelectOptions.quickSelectionItems"
        [(selectedItems)]="value[column.name]"
        [addDialogHeaderTKey]="column.chipSelectOptions.addDialogHeaderTKey ?? ''"
        [addDropdownPlaceholderTKey]="column.chipSelectOptions.addDropdownPlaceholderTKey?? ''"
        [disabled]="!column.editable"
      ></app-chip-select>
    </ng-template>
    <ng-template pTemplate="output">
      <app-chip-select
        *ngIf="column.chipSelectOptions"
        [optionLabel]="column.chipSelectOptions.itemLabel"
        [name]="column.name"
        [items]="column.chipSelectOptions.items"
        [quickSelectionItems]="column.chipSelectOptions.quickSelectionItems"
        [(selectedItems)]="value[column.name]"
        [addDialogHeaderTKey]="column.chipSelectOptions.addDialogHeaderTKey ?? ''"
        [addDropdownPlaceholderTKey]="column.chipSelectOptions.addDropdownPlaceholderTKey ?? ''"
        [disabled]="true"
      ></app-chip-select>
    </ng-template>
  </p-cellEditor>
</ng-container>
