import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  showSpinnerState$ = new BehaviorSubject<boolean>(false);


  show() {
    this.showSpinnerState$.next(true);
  }

  hide() {
    this.showSpinnerState$.next(false);
  }

  toggle() {
    this.showSpinnerState$.next(!this.showSpinnerState$.value);
  }
}
