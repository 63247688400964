import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import {
  TableColumn,
  TableColumnType,
} from 'src/app/modules/shared/components/table/table.model';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { CustomColumnDirective } from 'src/app/modules/shared/components/table-column/custom-column.directive';

@Component({
  selector: 'app-table-column',
  templateUrl: './table-column.component.html',
  styleUrl: './table-column.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class TableColumnComponent<T> {
  @Input({ required: true }) column!: TableColumn;
  @Input({ required: true }) value!: any;
  @Input({ required: true }) form!: NgForm;
  protected readonly TableColumnType = TableColumnType;
  @Input() isEditingNew: boolean = false;

  @ContentChild(CustomColumnDirective, { read: TemplateRef })
  customColumnTemplate!: TemplateRef<{
    $implicit: T;
    column: TableColumn;
  }>;

  isPasswordVisible = false;

  constructor(private toast: ToastService) {}

  setPasswordColumnVisibility(column: TableColumn, visible: boolean) {
    this.isPasswordVisible = visible;
  }

  copy(val: string) {
    navigator.clipboard.writeText(val).then(() => {
      this.toast.info('common.copied', 'common.copied_to_clipboard');
    });
  }

  protected readonly Object = Object;
}
