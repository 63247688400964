import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TileSelectItem} from "src/app/modules/shared/components/tile-select/tile-select.component";
import {MultiSelect, MultiSelectChangeEvent} from "primeng/multiselect";
import {TranslateService} from "@ngx-translate/core";


/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface ChipSelectItem {
  label: string;
  value: any;
  icon?: string;
  count?: number;
}

@Component({
  selector: 'app-chip-select',
  templateUrl: './chip-select.component.html',
  styleUrl: './chip-select.component.scss'
})
export class ChipSelectComponent {
  @ViewChild('ms') ms!: MultiSelect;
  @Input() name: string = 'chip-select';
  @Input() addDialogHeaderTKey: string = 'common.unknown';
  @Input() addDropdownPlaceholderTKey: string = 'common.unknown';
  @Input() optionLabel: string | undefined = undefined;
  @Input() multiple: boolean = false;
  @Input({required: true}) items: ChipSelectItem[] | undefined = [];
  @Input() quickSelectionItems!: TileSelectItem[] | undefined;

  @Input({required: true})
  set selectedItems(items: any[] | undefined) {
    if (!items) {
      return;
    }
    this._selectedItems = items.map(x => {
      if (!this.items) {
        return {label: x.name, value: x};
      }
      const item = this.items.find(y => this.optionLabel !== undefined ? y.value[this.optionLabel] === x[this.optionLabel] : y.value === x);
      return item ?? {label: x.name, value: x};
    });
    this._selectedItemsValues = items;
    this.selectedItemsChange.emit(this._selectedItemsValues);
  }

  get selectedItems(): any[] {
    return this._selectedItemsValues;
  }

  @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  set quickSelectItems(items: any[]) {
    this._quickSelectItems = items.map(x => {
      if (!this.items) {
        return {label: x.name, value: x};
      }
      const item = this.items.find(y => this.optionLabel !== undefined ? y.value[this.optionLabel] === x[this.optionLabel] : y.value === x);
      return item ?? {label: x.name, value: x};
    });
    this._quickSelectItemsValues = items;
  }

  get quickSelectItems(): any[] {
    return this._quickSelectItemsValues;
  }

  @Input() disabled: boolean = false;

  isAddDialogVisible = false;
  newItems: any[] | undefined;

  _selectedItems: ChipSelectItem[] = [];
  _selectedItemsValues: any[] = [];

  _quickSelectItems: TileSelectItem[] = [];
  _quickSelectItemsValues: any[] = [];
  selectedItemsLabel: string = this.t.instant('chip_select.x_items_selected');

  constructor(
    private t: TranslateService
  ) {
  }

  filterSelectedItemsDuplicates(items: any[]): { count: number, item: any }[] {
    const counts: number[] = [];
    const filteredItems: any[] = [];

    items.forEach(item => {
      if (!filteredItems.includes(item)) {
        filteredItems.push(item);
        counts.push(1);
      } else {
        counts[filteredItems.indexOf(item)]++;
      }
    });

    return filteredItems.map((item, index) => {
      return {
        count: counts[index],
        item: item,
      }
    });
  }

  itemClick(event: MultiSelectChangeEvent) {
    if (!event.value.includes(event.itemValue)) {
      event.itemValue.count = undefined;
      this.removeAdditionalItem(event.itemValue);
      return;
    }
    event.itemValue.count = 1;
  }

  removeItem(item: any): void {
    this._selectedItemsValues.splice(this._selectedItemsValues.indexOf(item), 1);
  }

  addItem() {
    this.resetTileSelect();
    this.isAddDialogVisible = true;
  }

  addAdditionalItem(event: MouseEvent, item: ChipSelectItem) {
    event.stopPropagation();
    if (!this.newItems) {
      this.newItems = [];
    }

    if (item.count === undefined) {
      item.count = 0;
    }

    item.count++;
    this.newItems.push(item);
  }

  removeAdditionalItemEvent(event: MouseEvent, item: ChipSelectItem) {
    event.stopPropagation();
    this.removeAdditionalItem(item)
  }

  removeAdditionalItem(item: ChipSelectItem) {
    if (!this.newItems) {
      this.newItems = [];
    }

    if (!item.count) {
      return;
    }
    item.count--;

    if (item.count === -1) {
      item.count = undefined;
      return;
    }
    this.newItems.splice(this.newItems.indexOf(item), 1);
  }

  saveAddItem() {
    this.isAddDialogVisible = false;

    this._quickSelectItems.forEach(x => {
      this._selectedItems.push({
        label: x.label,
        value: x.value,
        icon: x.icon,
      });
    })
    this._quickSelectItemsValues.forEach(x => {
      this._selectedItemsValues.push(x);
    });

    if (!this.newItems) {
      return;
    }

    this.newItems.forEach(x => {
      this._selectedItems.push(x);
      this._selectedItemsValues.push(x.value);
    });
    this.newItems = undefined;
  }

  resetTileSelect() {
    this.newItems = [];
    if (!this.items) {
      return;
    }
    this.items.forEach(x => {
      x.count = undefined;
    });

    this._quickSelectItems = [];
    this._quickSelectItemsValues = [];
    if (!this.quickSelectionItems) {
      return;
    }
    this.quickSelectionItems = this.quickSelectionItems.map(x => {
      return {
        label: x.label,
        value: x.value,
        icon: x.icon,
      };
    });
  }
}
