import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeMap'
})
export class TypeMapPipe implements PipeTransform {

  private typeMapping: { [key: string]: string } = {
    'FN': 'Full Name',
    'N': 'Name',
    'NICKNAME': 'Nickname',
    'PHOTO': 'Photo',
    'BDAY': 'Birthday',
    'ANNIVERSARY': 'Anniversary',
    'GENDER': 'Gender',
    'ADR;TYPE=HOME': 'Home Address',
    'ADR;TYPE=WORK': 'Work Address',
    'TEL;TYPE=HOME': 'Home Phone',
    'TEL;TYPE=WORK': 'Work Phone',
    'EMAIL;TYPE=HOME': 'Home Email',
    'EMAIL;TYPE=WORK': 'Work Email',
    'IMPP': 'Instant Messaging',
    'LANG': 'Language',
    'TZ': 'Time Zone',
    'GEO': 'Geolocation',
    'TITLE': 'Title',
    'ROLE': 'Role',
    'LOGO': 'Logo',
    'ORG': 'Organization',
    'MEMBER': 'Member',
    'RELATED': 'Related',
    'CATEGORIES': 'Categories',
    'NOTE': 'Note',
    'PRODID': 'Product ID',
    'REV': 'Revision',
    'SOUND': 'Sound',
    'UID': 'UID',
    'CLIENTPIDMAP': 'Client PID Map',
    'URL;TYPE=HOME': 'Home URL',
    'URL;TYPE=WORK': 'Work URL',
    'VERSION': 'Version'
  };

  transform(value: string): string {
    return this.typeMapping[value] || value;
  }

}
