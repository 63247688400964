import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  TableColumn,
  TableColumnType,
} from 'src/app/modules/shared/components/table/table.model';
import { Column } from 'src/app/modules/shared/base/component-with-table/extended-table';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, Subject } from 'rxjs';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Sort, SortOrder } from 'src/app/models/graphql/filter/sort.model';
import { FormControl, FormGroup } from '@angular/forms';
import { AddActionDirective } from 'src/app/modules/shared/components/table/add-action.directive';
import { EditActionDirective } from 'src/app/modules/shared/components/table/edit-action.directive';
import { DeleteActionDirective } from 'src/app/modules/shared/components/table/delete-action.directive';
import { MenuItem } from 'primeng/api';
import { CustomActionsDirective } from 'src/app/modules/shared/components/table/custom-actions.directive';
import { CustomColumnDirective } from 'src/app/modules/shared/components/table-column/custom-column.directive';
import { QueryResult } from 'src/app/models/entities/query-result';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class TableComponent<T> implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;

  @Input({ required: true }) name!: string;

  @Input({ required: true }) result!: QueryResult<T>;
  @Input({ required: true }) countHeaderTranslation!: string;
  @Input({ required: true }) columns!: TableColumn[];

  @Input() hidePaginator?: boolean;
  @Input() contextMenu?: MenuItem[];

  protected _contextMenuItem!: T;
  set contextMenuItem(value: T) {
    this._contextMenuItem = value;
    this.openContextMenu.emit();
  }

  get contextMenuItem() {
    return this._contextMenuItem;
  }

  @Input() filterInterceptor?: (
    attribute: string,
    value: string
  ) => Filter | undefined;
  @Input() sortInterceptor?: (field: string, value: string) => Sort;
  @Input() newNodeTemplate?: T = {} as T;
  @Input() rowDisabled?: (item: T) => boolean;

  @Output() reloadData: EventEmitter<void> = new EventEmitter<void>();
  @Output() columnHide: EventEmitter<Column[]> = new EventEmitter<Column[]>();
  @Output() add: EventEmitter<T> = new EventEmitter<T>();
  @Output() edit: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<T> = new EventEmitter<T>();
  @Output() openContextMenu: EventEmitter<void> = new EventEmitter<void>();

  private _hiddenColumns: Column[] = [];
  set hiddenColumns(value: Column[]) {
    this._hiddenColumns = value;
    localStorage.setItem('hiddenColumns', JSON.stringify(value));
    this.columnHide.emit(value);
  }

  get hiddenColumns(): Column[] {
    return this._hiddenColumns;
  }

  protected get hideableColumns(): Column[] {
    return this.columns
      .filter(column => column.hideable)
      .map(x => {
        return {
          key: this.getKey(x.label),
          name: x.label,
        };
      });
  }

  protected get editable() {
    return this.columns.some(column => column.editable);
  }

  protected get sortable() {
    return this.columns.some(column => column.sortable);
  }

  protected get filterable() {
    return this.columns.some(column => column.filterable);
  }

  protected loading: boolean = false;
  protected rowsPerPageOptions = [100, 250, 500];
  public skip = 0;
  public take = 100;
  public sort: { [key: string]: string | number | boolean } = {};
  public filter: Filter = {};
  protected filterForm!: FormGroup;
  protected defaultFilterForm!: FormGroup;

  public isEditingNew: boolean = false;
  public isEditing: boolean = false;

  private clonedNodes: T[] = [];

  protected unsubscriber = new Subject<void>();

  @ContentChild(CustomActionsDirective, { read: TemplateRef })
  customActionTemplate!: TemplateRef<any>;
  @ContentChild(AddActionDirective, { read: TemplateRef })
  addActionTemplate!: TemplateRef<any>;
  @ContentChild(EditActionDirective, { read: TemplateRef })
  editActionTemplate!: TemplateRef<any>;
  @ContentChild(DeleteActionDirective, { read: TemplateRef })
  deleteActionTemplate!: TemplateRef<any>;
  @ContentChild(CustomColumnDirective, { read: TemplateRef })
  customColumnTemplate!: TemplateRef<{
    $implicit: T;
    column: TableColumn;
    edit?: 'input' | 'output';
  }>;

  constructor() {}

  async ngOnInit() {
    this.setDefaultFilterForm();
    this.setFilterForm();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public setDefaultFilterForm() {
    this.defaultFilterForm = new FormGroup({});
    this.columns
      .filter(x => x.filterable)
      .forEach(x => {
        let control!: FormControl;

        if (x.fuzzyFilterColumns) {
          control = new FormControl<string | null>(null);
          this.defaultFilterForm.addControl('fuzzy', control);
          return;
        } else if (x.type === TableColumnType.STRING) {
          control = new FormControl<string | null>(null);
        } else if (x.type === TableColumnType.NUMBER) {
          control = new FormControl<number | null>(null);
        } else if (x.type === TableColumnType.BOOLEAN) {
          control = new FormControl<boolean | null>(null);
        } else if (x.type === TableColumnType.DATE) {
          control = new FormControl<Date | null>(null);
        } else {
          control = new FormControl<any | null>(null);
        }

        this.defaultFilterForm.addControl(x.name, control);
      });
  }

  public setFilterForm() {
    this.filterForm = this.defaultFilterForm;

    this.filterForm.valueChanges
      .pipe(takeUntil(this.unsubscriber), debounceTime(600))
      .subscribe(changes => {
        if (this.filterForm.disabled) {
          return;
        }

        this.filter = {};
        for (const atr in changes) {
          const value = changes[atr];

          if (value) {
            let interceptedFilter: Filter | undefined = undefined;
            if (this.filterInterceptor) {
              interceptedFilter = this.filterInterceptor(atr, value);
            }

            let filter: Filter = {};
            if (interceptedFilter) {
              filter = interceptedFilter;
            } else {
              filter[atr] = {};
              filter[atr] = value;
            }

            this.filter = filter;
          }
        }

        this.reloadData.emit();
      });
  }

  public resetFilters(): void {
    this.filterForm.reset();
  }

  public resetSort(table: Table): void {
    this.sort = {};
    table.reset();
  }

  public nextPage(event: TableLazyLoadEvent): void {
    this.take = event.rows ?? 10;
    if (event.first != null && event.rows != null)
      this.skip = (event.first / event.rows) * this.take;

    if (event.sortField) {
      let newSort: Sort = {};
      const field = event.sortField
        .toString()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
      const value =
        event.sortOrder === 1
          ? SortOrder.ASC
          : event.sortOrder === -1
            ? SortOrder.DESC
            : SortOrder.ASC;
      if (this.sortInterceptor) {
        newSort = this.sortInterceptor(field, value);
      } else {
        newSort[field] = value;
      }
      this.sort = newSort;
    }

    this.reloadData.emit();
  }

  public addNewNode() {
    const newNode = JSON.parse(JSON.stringify(this.newNodeTemplate ?? {}));
    this.result.nodes = [newNode, ...this.result.nodes];

    this.dt.initRowEdit(newNode);
    this.onRowEditInit(newNode, this.result.nodes.length);
    this.isEditingNew = true;
    this.filterForm.disable();
  }

  public onRowEditInit(object: T, index: number): void {
    this.isEditing = true;
    this.clonedNodes[index] = { ...object };
  }

  public onRowEditCancel(index: number): void {
    this.isEditing = false;
    this.filterForm.enable();
    if (this.isEditingNew) {
      this.result.nodes.splice(index, 1);
      delete this.clonedNodes[index];
      this.isEditingNew = false;
      return;
    }

    this.result.nodes[index] = this.clonedNodes[index];
    delete this.clonedNodes[index];
  }

  public onRowEditSave(node: T, index: number) {
    if (
      this.isEditingNew &&
      JSON.stringify(node) === (this.newNodeTemplate ?? ({} as T))
    ) {
      this.isEditingNew = false;
      this.result.nodes.splice(index, 1);
      return;
    }
    this.isEditing = false;

    if (this.isEditingNew) {
      this.add.emit(node);
      this.isEditingNew = false;
      return;
    }
    this.edit.emit(node);
  }

  private getKey(column: string): string {
    return `${this.name}_${column}`;
  }

  public isColumnVisible(column: string): boolean {
    return !this._hiddenColumns
      .map(column => column.key)
      .includes(this.getKey(column));
  }

  protected getFuzzyColspan(columns: string[]): number {
    return this.columns.filter(
      x => columns.includes(x.label) && this.isColumnVisible(x.label)
    ).length;
  }

  protected readonly TableColumnType = TableColumnType;
}
