import {ChipSelectItem} from "src/app/modules/shared/components/chip-select/chip-select.component";

export enum TableColumnType {
  ID = 'id',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ENUM = 'enum',
  COMMENT = 'comment',
  DROPDOWN = 'dropdown',
  CHIPSELECT = 'chipselect',
  PASSWORD = 'password',
  CUSTOM = 'custom',
}


export enum TableColumnSize {
  SMALLER = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  LARGER = 'xl',
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface TableColumnChipSelectOption {
  itemLabel?: string;
  items?: ChipSelectItem[];
  quickSelectionItems?: ChipSelectItem[];
  addDialogHeaderTKey?: string;
  addDropdownPlaceholderTKey?: string;
  multiple?: boolean;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface TableColumnDropdownOption {
  dataKey?: string;
  optionLabel?: string;
  optionValue?: string;
  placeholder?: string;
  options?: any[];
  filterable?: boolean;
  filterBy?: string;
}

export interface TableRequiredPermissions {
  add?: string;
  edit?: string;
  delete?: string;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface TableColumn {
  translation: string;
  // for display
  label: string;
  // for data access
  name: string;
  type: TableColumnType,
  valueGetter?: (value: any) => any;
  valuePipe?: (value: any) => any;
  hideable?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  editable?: boolean;
  required?: boolean;
  currency?: string;
  fuzzyFilterColumns?: string[];
  size?: TableColumnSize;
  dropdownOptions?: TableColumnDropdownOption;
  chipSelectOptions?: TableColumnChipSelectOption;
}
