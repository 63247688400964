import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Redirect } from 'src/app/models/entities/redirect';
import { Apollo, gql } from 'apollo-angular';
import { User } from 'src/app/models/entities/user';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  user$ = new BehaviorSubject<User | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) {}

  getApiVersion(): Observable<string> {
    return this.http.get(`${environment.api.url}/version`, {
      responseType: 'text',
    });
  }

  redirect(code: string): Observable<Redirect | undefined> {
    return this.http.get<Redirect | undefined>(
      `${environment.api.url}/redirects/${code}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  getRedirects(code: string): Observable<Redirect | undefined> {
    return this.http.get<Redirect | undefined>(
      `${environment.api.url}/redirects/get/${code}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  getUser(): Observable<User> {
    return this.apollo
      .query<{
        user: User;
      }>({
        query: gql`
          query user {
            user {
              id
              identity
              name
              email
              role
              categoryPermissions {
                category {
                  id
                }
                canEdit
                canDelete
              }
            }
          }
        `,
      })
      .pipe(
        map(response => response.data.user),
        catchError(err => {
          throw err;
        })
      );
  }

  getRandomRedirectURL(): Observable<string> {
    return this.apollo
      .query<{
        newRandomRedirectUrl: string;
      }>({
        query: gql`
          query user {
            newRandomRedirectUrl
          }
        `,
      })
      .pipe(
        map(response => response.data.newRandomRedirectUrl),
        catchError(err => {
          throw err;
        })
      );
  }
}
