<p-sidebar [(visible)]="visible" position="right" [style]="{ width: '50rem' }" [modal]="true">
  <ng-template pTemplate="headless">
    <div class="flex flex-col gap-50 w-full padding-10 overflow-auto">
      <div *ngIf="!headerTemplate" class="flex">
        <div class="flex flex-1 gap-5">
          <div>
            <p-button class="btn icon-btn" icon="pi pi-times"
                      (onClick)="visible = false"></p-button>
          </div>
          <h2>{{ header | translate }}</h2>
        </div>

        <div>
          <p-button label="{{'common.save' | translate}}" class="btn" icon="pi pi-check"
                    (onClick)="save.emit()" [disabled]="form.invalid ?? false"></p-button>
        </div>
      </div>
      <ng-container *ngIf="headerTemplate">
        <ng-container *ngTemplateOutlet="contentTemplate;"></ng-container>
      </ng-container>

      <ng-container *ngTemplateOutlet="contentTemplate;"></ng-container>
    </div>
  </ng-template>
</p-sidebar>
