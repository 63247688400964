<div class="chip-select">
  <div *ngFor="let item of _selectedItems">
    <div *ngIf="item.icon; else normal">
      <p-chip [removable]="!disabled" (onRemove)="removeItem(item.value)">
        <div class="flex justify-center items-center gap-5">
          <i [class]="item.icon"></i>
          <span>{{ item.label }}</span>
        </div>
      </p-chip>
    </div>
    <ng-template #normal>
      <p-chip label="{{ item.label }}" [removable]="!disabled" (onRemove)="removeItem(item.value)"></p-chip>
    </ng-template>
  </div>
  <div class="chip-add" *ngIf="!disabled">
    <p-chip styleClass="chip-add-item" icon="pi pi-plus" label="{{ 'common.add' | translate }}"
            (click)="addItem()"></p-chip>
  </div>
</div>

<p-dialog [(visible)]="isAddDialogVisible" (onHide)="resetTileSelect()" [modal]="true" [draggable]="false"
          [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    {{ addDialogHeaderTKey | translate }}
  </ng-template>
  <div class="flex flex-col gap-15">
    <div *ngIf="quickSelectionItems" class="w-full justify-center items-center">
      <app-tile-select [items]="quickSelectionItems" [(selectedItems)]="quickSelectItems"></app-tile-select>
    </div>
    <div>
      <p-multiSelect #ms [name]="name" [options]="items" [(ngModel)]="newItems" dataKey="value" optionLabel="label"
                     placeholder="{{addDropdownPlaceholderTKey | translate}}" [selectedItemsLabel]="selectedItemsLabel"
                     [filter]="true" filterBy="label" (onChange)="itemClick($event)">
        <ng-template let-selectedItems pTemplate="selectedItems" *ngIf="multiple">
          <div *ngIf="!selectedItems || selectedItems.length === 0; else listView"
               class="p-element p-multiselect-label-container">
            <div class="p-multiselect-label p-placeholder">{{ ms.placeholder() }}</div>
          </div>

          <ng-template #listView>
            <div class="p-multiselect-label">
              <ng-container *ngFor="let item of filterSelectedItemsDuplicates(selectedItems)">
                <span class="item"><span *ngIf="item.item.count && item.item.count > 1">{{ item.item.count }}x</span> {{ item.item.label }}</span>
              </ng-container>
            </div>
          </ng-template>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="w-full flex space-between">
            <div class="flex gap-10 items-center">
              <div *ngIf="multiple && item.count">
                <p-badge [value]="item.count.toString()"/>
              </div>
              <div>{{ item.label }}</div>
            </div>
            <div *ngIf="multiple">
              <p-button class="btn icon-btn" icon="pi pi-plus"
                        (onClick)="addAdditionalItem($event, item)"></p-button>
              <p-button class="btn icon-btn" icon="pi pi-minus"
                        (onClick)="removeAdditionalItemEvent($event, item)"></p-button>
            </div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="wrapper-right btn-wrapper">
      <p-button label="{{'common.add' | translate}}" class="btn" icon="pi pi-check-circle"
                (onClick)="saveAddItem()"></p-button>
    </div>
  </ng-template>
</p-dialog>
