import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Injectable()
export class AuthGuard {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private dataService: DataService,
    private spinner: SpinnerService
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.oidcSecurityService.checkAuth().subscribe(loginResponse => {
        if (loginResponse.isAuthenticated) {
          this.dataService
            .getUser()
            .pipe(
              catchError(e => {
                this.spinner.hide();
                throw e;
              })
            )
            .subscribe(user => {
              this.dataService.user$.next(user);
            });
          resolve(true);
        } else {
          this.oidcSecurityService.authorize();
          resolve(false);
        }
      });
    });
  }
}
